.checkboxwrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0px;
}
.checkboxwrapper input[type="checkbox"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 1.6em;
    height: 1.6em;
    border-radius: 0.15em;
    margin-right: 0.5em;
    border: 0.15em solid #c0c0c0;
    outline: none;
    cursor: pointer;
    
}

.checkboxwrapper input:checked {
    padding:3px;
    background-color: #fad6d6;
    border-color: #fad6d6;
    outline:#fff;
    color: #fff;
    position: relative;
}
  
input:checked::before {
    content: "";
    font-size: 1.5em;
    color: #fad6d6;
    position: absolute;
    right: 1px;
    top: -5px;
}

.checkboxwrapper input[type="checkbox"]:disabled {
    border-color: #c0c0c0;
    background-color: #c0c0c0;
}

.checkboxwrapper input[type="checkbox"]:disabled + span {
    color: #c0c0c0;
}
  
.checkboxwrapper input[type="checkbox"]:focus {
    box-shadow: 0 0 20px #fad6d6;
}